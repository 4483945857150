import React, { useState } from "react"
import { Link } from "gatsby"

import { Transition } from "@headlessui/react"

import SEO from "../components/seo"
import Footer from "../components/Footer"
import NavBar from "../components/NavBar"

export default function Page() {
  return (
    <>
      <SEO title="Home" />
      <Hero />
      <Features />
      <Cloud />
      <UseCases />
      <FinDev />
      <CostCalculator />
      <Examples />
      <Articles />
      <Quotes />
      <Framework />
    </>
  )
}

const Hero = () => {
  return (
    <div className="bg-gray-50">
      <div className="relative overflow-hidden">
        <div className="relative pt-6 pb-16 sm:pb-24">
          <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl space-y-4">
                <span className="block">The easiest way to</span>
                <span className="block text-brand">
                  Design, Model and Launch
                </span>
                <span className="block ">Data-driven applications</span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Engage your whole team in designing, testing and getting your
                ‘data driven’ idea into production faster and more cost
                effectively than ever before
              </p>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="absolute inset-0 flex flex-col" aria-hidden="true">
            <div className="flex-1"></div>
            <div className="flex-1 w-full bg-gray-800"></div>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-12">
            <img
              className="relative rounded-lg shadow-lg"
              src="/images/furnace-ui2.jpg"
              alt="App screenshot"
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
          {/* <h2 className="text-center text-gray-400 text-sm font-semibold uppercase tracking-wide">
            Furnace in the news
          </h2> */}
          <div className="mt-8 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img
                className="h-8"
                src="/images/brands/thenewstack.png"
                alt="thenewstack"
              />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img
                className="h-8"
                src="/images/brands/devops.com.png"
                alt="DevOps.com"
              />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img
                className="h-8"
                src="/images/brands/bi.png"
                alt="Business Insider"
              />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
              <img
                className="h-8"
                src="/images/brands/nasdaq.png"
                alt="Nasdaq"
              />
            </div>
            <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
              <img
                className="h-8"
                src="/images/brands/telegraph.png"
                alt="Telegraph"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Features = props => {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        {/* <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg> */}

        {/* Heading */}
        <div className="relative">
          <h2 className="text-center text-4xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">
            A fresh approach to building applications
          </h2>
          <h3 className="text-center text-2xl leading-8 font-extrabold tracking-tight text-gray-600 sm:text-3xl">
            <span>
              Build <span className="text-brand">10x</span> Faster
            </span>
            <br />
            <span>
              Reduce Cloud Cost by up to <span className="text-brand">90%</span>
            </span>
          </h3>
        </div>

        {/* feature 1 */}
        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h2 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              1. Design
            </h2>
            <p className="mt-3 text-xl text-gray-500">
              ’Drag & Drop’ pre-built components, integrating 1000’s of API’s,
              Databases and Cloud Services to build ‘data driven’ apps 10x
              faster
            </p>

            {/* <dl className="mt-10 space-y-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Competitive exchange rates
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Maiores impedit perferendis suscipit eaque, iste dolor
                    cupiditate blanditiis ratione.
                  </dd>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    No hidden fees
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Maiores impedit perferendis suscipit eaque, iste dolor
                    cupiditate blanditiis ratione.
                  </dd>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Transfers are instant
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Maiores impedit perferendis suscipit eaque, iste dolor
                    cupiditate blanditiis ratione.
                  </dd>
                </div>
              </div>
            </dl>
           */}
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width="784"
              height="404"
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="784"
                height="404"
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <img
              className="relative mx-auto"
              width="490"
              src="/images/screens/f-design.png"
              alt=""
            />
          </div>
        </div>

        {/* <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg> */}

        {/* feature 2 */}
        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                2. Model
              </h3>
              <p className="mt-3 text-xl text-gray-500">
                Dynamically model the cost of the component and application per
                transaction and achieve up to 90% savings
              </p>

              {/* <dl className="mt-10 space-y-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Mobile notifications
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Maiores impedit perferendis suscipit eaque, iste dolor
                      cupiditate blanditiis ratione.
                    </dd>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Reminder emails
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Maiores impedit perferendis suscipit eaque, iste dolor
                      cupiditate blanditiis ratione.
                    </dd>
                  </div>
                </div>
              
              </dl>
             */}
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width="784"
                height="404"
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="784"
                  height="404"
                  fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                />
              </svg>
              <img
                className="relative mx-auto"
                width="490"
                src="/images/screens/f-model.png"
                alt=""
              />
            </div>
          </div>
        </div>

        {/* feature 3 */}
        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              3. Launch
            </h3>
            <p className="mt-3 text-xl text-gray-500">
              Once ready to test, commit the application via ‘Publish’.
              Everything is automatically versioned, audited and pushed into
              production.
            </p>

            {/* <dl className="mt-10 space-y-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Competitive exchange rates
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Maiores impedit perferendis suscipit eaque, iste dolor
                    cupiditate blanditiis ratione.
                  </dd>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    No hidden fees
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Maiores impedit perferendis suscipit eaque, iste dolor
                    cupiditate blanditiis ratione.
                  </dd>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Transfers are instant
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Maiores impedit perferendis suscipit eaque, iste dolor
                    cupiditate blanditiis ratione.
                  </dd>
                </div>
              </div>
            </dl>
           */}
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width="784"
              height="404"
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="784"
                height="404"
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <img
              className="relative mx-auto"
              width="490"
              src="/images/screens/f-launch.jpg"
              alt=""
            />
          </div>
        </div>

        {/* feature 4 */}
        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                4. Manage
              </h3>
              <p className="mt-3 text-xl text-gray-500">
                See how your application performs in real time while managing
                business risk
              </p>

              {/* <dl className="mt-10 space-y-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Mobile notifications
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Maiores impedit perferendis suscipit eaque, iste dolor
                      cupiditate blanditiis ratione.
                    </dd>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Reminder emails
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Maiores impedit perferendis suscipit eaque, iste dolor
                      cupiditate blanditiis ratione.
                    </dd>
                  </div>
                </div>
              </dl>
             */}
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width="784"
                height="404"
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="784"
                  height="404"
                  fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                />
              </svg>
              <img
                className="relative mx-auto"
                width="490"
                src="/images/screens/f-manage.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Cloud = props => {
  return (
    <section className="bg-brand">
      <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
        {/* section 1 */}
        <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-white lg:pr-16">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            Furnace Cloud
          </h2>
          <h3 className="text-white">Fully managed Furnace applications</h3>
          <p className="mt-4 text-lg leading-6 text-white">
            Deploy in minutes. Pay as you go. Scale infinitely.
          </p>
          <Link
            to="/form/cloud-waitlist"
            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-brand bg-white hover:bg-indigo-50 sm:w-auto"
          >
            Join the wait-list
          </Link>
        </div>

        {/* section 2 */}
        <div className="py-12 px-4 md:flex md:flex-col border-t-2 border-brand sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            Furnace Platform
          </h2>
          <h3 className="text-white">The power of Furnace, self-managed</h3>
          <p className="mt-4 text-lg leading-6 text-white">
            Run's on your own Cloud, under your full control.
          </p>
          <Link
            to="/form/platform-enquire"
            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-brand bg-white hover:bg-indigo-50 sm:w-auto"
          >
            Enquire now
          </Link>
        </div>
      </div>
    </section>
  )
}

const Framework = () => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">The Furnace Framework</span>
        </h2>
        <h3 className="text-2xl font-extrabold tracking-tight text-gray-400 sm:text-3xl">
          <span className="block">
            Our rapid development framework allows you to build Serverless apps
            in a fraction of the time.
          </span>
        </h3>
        <p className="mt-4 text-lg leading-6 text-gray-600">
          Deploy in minutes. Pay as you go. Scale infinitely.
        </p>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <Link
              to="/framework"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand hover:opacity-90"
            >
              Learn more
            </Link>
          </div>
          <div className="ml-3 inline-flex">
            <a
              href="https://github.com/ProjectFurnace/furnace"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-brand border-brand bg-white hover:bg-brand hover:opacity-90 hover:text-white"
            >
              GitHub
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const UseCases = () => {
  return (
    <div className="bg-gray-50 overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
          />
        </svg>

        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="lg:col-span-1">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Use Cases
            </h2>
            <p className="mt-4 text-lg leading-6 text-gray-600">
              Build the future of Data Driven applications
            </p>
          </div>
          <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
            <div>
              <div className="flex items-center justify-center h-12 w-12 rounded-md text-white">
                <img src="/icons/financial-services.svg" />
              </div>
              <div className="mt-5">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  FinTech &amp; Financial Services
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  With a wide array of API’s and Financial platform
                  integrations, Furnace is your place to build FinTech
                  applications.
                </dd>
              </div>
            </div>

            <div>
              <div className="flex items-center justify-center h-12 w-12 rounded-md text-white">
                <img src="/icons/marketing.svg" />
              </div>
              <div className="mt-5">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Marketing
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  With over 7000+ MarTech platforms, you can build your perfect
                  Marketing Stack.
                </dd>
              </div>
            </div>

            <div>
              <div className="flex items-center justify-center h-12 w-12 rounded-md text-white">
                <img src="/icons/iot.svg" />
              </div>
              <div className="mt-5">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  IoT
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  With potentially millions of events from millions of devices,
                  manage your entire IoT fleet with Furnace.
                </dd>
              </div>
            </div>

            <div>
              <div className="flex items-center justify-center h-12 w-12 rounded-md text-white">
                <img src="/icons/healthcare.svg" />
              </div>
              <div className="mt-5">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Health Care
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Build data-driven HealthTech applications like, prognosis AI,
                  value based healthcare, personalised medicine or internet of
                  medical things.
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}

const FinDev = () => {
  const sections = [
    {
      title: "Capex to Opex",
      content:
        "Convert software operations from Capex (fixed costs) to Opex (variable costs). This means businesses can focus on marginal costs and value, making better decisions as a consequence.",
    },
    {
      title: "Profitability and Value",
      content:
        "Gain insight into a transaction’s revenue and cost relationship and understand how profitable and valuable that feature is to customers. ",
    },
    {
      title: "Identify Success",
      content:
        "Combining the understood value and how often a feature is used relative to operating costs and revenue, a business can decide which transaction to invest in or eliminate.",
    },
    {
      title: "Forecast Revenue and Cost",
      content:
        "With understood cost, revenue, and profitability for each feature and transaction, a business can forecast future values and growth rate.",
    },
    {
      title: "Focus on Growth",
      content:
        "With growth rates anticipated, a business can focus on those transactions that will see increase in value and usage. ",
    },
    {
      title: "Minimise Business Risk",
      content:
        "Moving to Serverless decreases DOL (Degree of Operating Leverage). Profit generation has a lower dependency upon fixed costs as features and services move over to variable cost infrastructure. ",
    },
    {
      title: "Lower Break-Even",
      content:
        "As fixed costs are switched for variable costs, the break-even point of the business becomes lower.",
    },
    {
      title: "Optimise",
      content:
        "Combining customer churn rate with how often a feature is used and its profitability, it is possible to understand which features are failing to serve customers and why.",
    },
  ]

  return (
    <div className="bg- white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">
            FinDev - Value Driven Development
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            The convergence of Finance and Software Operations and its many
            advantages are embedded in Furnace.
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {sections.map(({ title, content }) => (
            <div className="flex">
              {/* Heroicon name: check */}
              <svg
                className="flex-shrink-0 h-6 w-6 text-green-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <div className="ml-3">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  {title}
                </dt>
                <dd className="mt-2 text-base text-gray-500">{content}</dd>
              </div>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}

const CostCalculator = () => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-brand rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Cloud Cost Calculator</span>
              </h2>
              <h3 className="text-2xl font-extrabold text-white sm:text-3xl">
                <span className="block">
                  Estimate potential cost savings when moving to Furnace
                </span>
              </h3>
              {/* <p className="mt-4 text-lg leading-6 text-white">
                Ac euismod vel sit maecenas id pellentesque eu sed consectetur.
                Malesuada adipiscing sagittis vel nulla nec.
              </p> */}
              <a
                href="/calculator"
                className="mt-8 bg-white border border-transparent rounded-md shadow px-6 py-3 inline-flex items-center text-base font-medium text-brand hover:bg-indigo-50"
              >
                Run the Calculator
              </a>
            </div>
          </div>
          <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
            <img
              className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
              src="/images/screens/costcalc.png"
              alt="App screenshot"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const Examples = () => {
  const examples = [
    {
      type: "Data Warehousing",
      description: "3 million Website Clickstream Events to Data Warehouse",
      reduction: "94%",
      compare: [
        { name: "Segment", cost: "$700.00/mo" },
        { name: "Furnace", cost: "$43.55/mo" },
        ,
      ],
    },
    {
      type: "Marketing",
      description: "20,000 Facebook Ads Leads to Salesforce",
      reduction: "95%",
      compare: [
        { name: "Zapier Pro", cost: "$189.00/mo" },
        { name: "Furnace", cost: "$9.27/mo" },
        ,
      ],
    },
    {
      type: "Marketing",
      description: "Ingest 3 million customer profiles and events",
      reduction: "94%",
      compare: [
        { name: "Hull.io", cost: "$450.00/mo" },
        { name: "Furnace", cost: "$28.53/mo" },
        ,
      ],
    },
  ]

  return (
    <div className="bg-gray-900">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
              Some of the things you can build...
            </h2>
            <p className="text-xl text-gray-300">
              Furnace is you platform to rapidly build data-driven applications
              of all shapes and sizes.
            </p>
          </div>
          <ul className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8">
            {examples.map(example => (
              <li className="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
                <div className="space-y-6 xl:space-y-10">
                  {/* <img
                    className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                    src="https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                    alt=""
                  /> */}
                  <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                    <div className="font-medium text-lg leading-6 space-y-1">
                      <p className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        {example.type}
                      </p>
                      <h3 className="text-white">{example.description}</h3>

                      <p className="text-white underline">
                        {example.reduction} cost reduction
                      </p>
                      <div className="flex pt-6 justify-between text-white">
                        <span>{example.compare[0].name}</span>
                        <span>{example.compare[0].cost}</span>
                      </div>
                      <div className="flex justify-between text-white font-bold">
                        <span>{example.compare[1].name}</span>
                        <span>{example.compare[1].cost}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

const Articles = () => {
  const articles = [
    {
      type: "Article",
      title:
        "The Case for Furnace, a Data Fabric that Can Evolve with Your Business",
      content:
        "Furnace aims to become your data fabric, the platform around which your data-driven business is built. That’s a bold statement and needs context, so in this article I’ll attempt to explain...",
      author: "Danny Waite",
      date: "17 Dec 2019",
      url:
        "https://thenewstack.io/the-case-for-furnace-a-data-fabric-that-can-evolve-with-your-business/",
    },
    {
      type: "Article",
      title: "How Serverless Is Impacting the IT Landscape",
      content:
        "Although most users will never realize it, with every digital data entry and exchange servers are consuming substantial quantities of energy to file, store and sustain that data’s availability...",
      author: "John Blamire",
      date: "17 Dec 2019",
      url: "https://devops.com/how-serverless-is-impacting-the-it-landscape/",
    },
    {
      type: "Article",
      title:
        "Can Project Furnace solve DX dilemma by combining serverless computing and GitOps?",
      content:
        "Furnace, in essence, seeks to aid and abet digital transformation, or DX, the ongoing digitization of essentially all human endeavors ...",
      author: "Byron V. Acohido",
      date: "12 Feb 2019",
      url:
        "https://securityboulevard.com/2019/02/my-take-can-project-furnace-solve-dx-dilemma-by-combining-serverless-computing-and-gitops/",
    },
  ]

  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            Furnace Press
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Read about Furnace in the press
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {articles.map(article => (
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              {/* <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                  alt=""
                />
              </div> */}
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-brand">
                    <a href={article.url} className="hover:underline">
                      {article.type}
                    </a>
                  </p>
                  <a href={article.url} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">
                      {article.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {article.content}
                    </p>
                  </a>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href={article.url}>
                      <span className="sr-only">{article.author}</span>
                      {/* <img
                        className="h-10 w-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      /> */}
                    </a>
                  </div>
                  <div className="">
                    <p className="text-sm font-medium text-gray-900">
                      <a href={article.url} className="hover:underline">
                        {article.author}
                      </a>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime="2020-03-16">{article.date}</time>
                      {/* <span aria-hidden="true">&middot;</span> */}
                      {/* <span>6 min read</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const Quotes = () => {
  return (
    <section className="bg-brand">
      <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
        {/* quote 1 */}
        <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-white lg:pr-16">
          {/* <div className="md:flex-shrink-0">
            <img
              className="h-12"
              src="https://tailwindui.com/img/logos/tuple-logo-indigo-300.svg"
              alt="Tuple"
            />
          </div> */}
          <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
            <div className="relative text-lg font-medium text-white md:flex-grow">
              <svg
                className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-white text-opacity-20"
                fill="currentColor"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative">
                Our business model is dependent on the management of large,
                volatile, volumes of data, across a distributed and mobile
                network. Furnace made it possible to reduce our Cloud costs by
                almost 80% and allow our development team to get back to
                innovating instead of managing infrastructure.
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex">
                {/* <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                  <img
                    className="h-12 w-12 rounded-full"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div> */}

                <div className="text-base font-medium text-white">
                  Steve, FinTech CTO
                </div>
                {/* <div className="text-base font-medium text-indigo-200">
                    CEO, Tuple
                  </div> */}
              </div>
            </footer>
          </blockquote>
        </div>

        {/* quote 2 */}
        <div className="py-12 px-4 border-t-2 border-brand sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
          {/* <div className="md:flex-shrink-0">
            <img
              className="h-12"
              src="https://tailwindui.com/img/logos/workcation-logo-indigo-300.svg"
              alt="Workcation"
            />
          </div> */}
          <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
            <div className="relative text-lg font-medium text-white md:flex-grow">
              <svg
                className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-white text-opacity-20"
                fill="currentColor"
                viewBox="0 0 32 32"
              >
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative">
                From smart cities to marketing and security, vast new pools of
                data are unused or underemployed, both because of the scarcity
                of developer talent and the enormous commitment of time and
                money currently required to bring a complex application to
                market.”
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex flex-col">
                {/* <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                  <img
                    className="h-12 w-12 rounded-full"
                    src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div> */}
                <div className="text-lg text-white">
                  <a href="https://www.computerweekly.com/blog/Open-Source-Insider/Furnace-turns-up-heat-on-data-streaming-apps">
                    Furnace turns up heat on data streaming apps
                  </a>
                </div>
                <div className="text-base font-medium text-white">
                  Rik Turner, Principal Analyst, Ovum
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  )
}
